/* global VXConfig */

import React                            from 'react';
import {render, unmountComponentAtNode} from 'react-dom';
import PjaxWrapper                      from '../utils/PjaxWrapper';
import Modal                            from '../components/Modal';
import Translations                     from "../utils/Translations";
import Flux                             from '../flux/Flux';

function run(containerId, className) {
	const container       = document.getElementById(containerId);
	const smallScreen     = window.innerWidth < 768 ? true : false;
	const containerWidth  = smallScreen ? 500 : 785;
	const containerHeight = smallScreen ? 700 : 528;
	const pictureUrl      = smallScreen ? "/assets/img/modal/pornme_migration_mimisweet_mobile.jpg" : "/assets/img/modal/pornme_migration_mimisweet.jpg";
	const popupStyle      = {};
	if (smallScreen) {
		popupStyle['width'] = "500px";
	} else {
		popupStyle['margin-left']   = "0";
		popupStyle['margin-bottom'] = "-3px";
	}

	if (container, className, containerWidth, containerHeight, pictureUrl) {

		const doClose = (e) => {
			if (e && e.stopPropagation) {
				e.stopPropagation();
			}
			Flux.Browser.setShouldModalWindowClose();
		};

		const lineStyle = {fontSize: "1.6rem", marginTop: "12px", marginBottom: "5px", textAlign: "left"};
		const iconStyle = {color: "#43B31C", paddingRight: "5px", fontSize: "1.5rem"};

		render(<Modal
			className={className}
			dynamicSize={true}
			containerWidth={containerWidth}
		       >
			<div className="row">
				<div className={smallScreen ? "col-md-12 col-lg-12 col-sm-12 col-xs-12" : "col-md-5 col-lg-5 col-sm-5 col-xs-5"}>
					<img src={pictureUrl} alt="LexyRoxx" style={popupStyle} />
				</div>
				<div className={smallScreen ? "col-md-12 col-lg-12 col-sm-12 col-xs-12" : "col-md-7 col-lg-7 col-sm-7 col-xs-7"}>
					<div style={{
						"margin-top":   VXConfig.isVXMobilePlatform ? "5%" : (smallScreen ? "1%" : "10%"),
						"margin-left":  VXConfig.isVXMobilePlatform ? "3%" : (smallScreen ? "10%" : "15%"),
						"margin-right": VXConfig.isVXMobilePlatform ? "3%" : (smallScreen ? "10%" : "10%"),
						"text-align":   VXConfig.isVXMobilePlatform ? "3%" : (smallScreen ? "center" : "left"),
					}}
					>
						<img style={{width: "105px"}} src="/assets/img/vxlogo_shade_redblack.svg" />
						<p style={{
							"font-family":    "Roboto Condensed",
							"font-size":      VXConfig.isVXMobilePlatform ? "4rem" : "50px",
							"text-transform": "uppercase",
							"margin-top":     VXConfig.isVXMobilePlatform ? "5%" : "7%",
							"line-height":    "1em",
							"margin-bottom":  "10px",
						}}
						>{VXConfig.language === 'de' ? "Herzlich " : ''}<strong>{Translations.get('RestrictedAreaWelcome')}!</strong></p>
						<p style={{
							"font-family":   "Roboto",
							"font-size":     "20px",
							"margin-top":    "-3%",
							"margin-bottom": "0",
						}}
						>{Translations.get('InVisitxCommunity')}</p>

						<div style={{display: "inline-block", marginTop: VXConfig.isVXMobilePlatform ? "20px" : "40px"}}>
							<div style={lineStyle}><span style={iconStyle} className={"icon -icon-success-rounded-thinner"} /> Amateur-Videos</div>
							<div style={lineStyle}><span style={iconStyle} className={"icon -icon-success-rounded-thinner"} /> Live-Cams</div>
							<div style={lineStyle}><span style={iconStyle} className={"icon -icon-success-rounded-thinner"} /> private {Translations.get('ContestTypePhoto')}</div>
							<div style={lineStyle}><span style={iconStyle} className={"icon -icon-success-rounded-thinner"} /> {Translations.get('ExclusivePromotions')}</div>
						</div>

						{!VXConfig.isVXMobilePlatform && <span><br /><br /></span>}
						<div style={{width: "100%"}}>
							<button onClick={doClose}
							        className="button -btn-color-special js-pjax-link"
							        style={{
								        "margin-bottom": "30px",
								        "margin-top":    "30px",
							        }}
							>{Translations.get('GetStarted')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>, container);

		PjaxWrapper.addUnloadFunc(() => {
			unmountComponentAtNode(container);
		});
	}
}

export {run};
